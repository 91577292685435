<template>
  <Layout class="bg-s-50">
    <div
      class="max-w-content-container mx-auto mt-[46px] flex h-full w-full flex-grow flex-col px-0 lg:mt-0 lg:flex-row lg:px-14 2xl:px-0"
    >
      <Sidebar
        :key="fullPath"
        :menus="menus"
        :service="service"
        :active-menu="activeMenu"
        :active-sub-menu="activeSubMenu"
      />
      <LayoutPreviewSite class="min-w-0">
        <div class="h-full w-full min-w-0 px-0">
          <slot />
        </div>
      </LayoutPreviewSite>
    </div>
  </Layout>
</template>

<script setup lang="ts">
/** components */
import Layout from '~/components/TheLayout.vue'
import LayoutPreviewSite from '~/components/PreviewSiteLayout.vue'
import Sidebar from '~/components/common/Sidebar.vue'

/** constants */
import { MARKET } from '~/constants/services/constants'

/** stores */
import { useSidebarStore } from '~/stores/sidebar'

type MenuItem = {
  name: string
  label: string
  icon: string
  to: string
  open: boolean
  children: MenuChild[] | []
  isPrivate: boolean
}

type MenuChild = {
  name: string
  to: string
  page: string
}

/** app */
const { state } = useSidebarStore()
const $route = useRoute()

/** computed */
const fullPath = computed(() => $route.fullPath)
const activeMenu = computed(
  () => $route.path.split('/')[1]?.toLowerCase() || 'home',
)
const activeSubMenu = computed(
  () => $route.path.split('/')[2]?.toLowerCase() || '',
)
const service = computed(() => $route.path.split('/')[1])
const menus = computed(() => {
  const sidebar: MenuItem[] = state[MARKET.slug]?.map((menuItem: MenuItem) => ({
    ...menuItem,
    icon: menuItem.icon ? `sidebar/${menuItem.icon}` : 'sidebar/Home',
  }))
  return sidebar ?? []
})
</script>
